<template>
    <div id="map" class="map"></div>
  </template>
  
  <script>
  import Map from 'ol/Map';
  import View from 'ol/View';
  import TileLayer from 'ol/layer/Tile';
  import OSM from 'ol/source/OSM';
  
  export default {
    name: 'MyMap',
    mounted() {
      new Map({
        target: 'map',
        layers: [
          new TileLayer({
            source: new OSM()
          })
        ],
        view: new View({
          center: [0, 0],
          zoom: 2
        })
      });
    }
  }
  </script>
  
  <style scoped>
  .map {
    height: 100vh;
    width: 100%;
  }
  </style>